<template>
	<div>
		<a-form ref="form" :model="modelRef" :label-col="{ span: 6 }">
			<h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">
				预警时间设置</h4>
			
			<a-form-item label="希望任务提交时间前" name="num" :rules="[{required: true, message: '必填项不允许为空'}]">
				<a-input-number v-model:value="modelRef.num" placeholder="请输入" :min="0" :precision="0"></a-input-number> 天提醒
			</a-form-item>
		</a-form>
		<div class="ui-btnBox" style="width: 200px;">
			<a-button v-permission="['pc_system_time_submit']" type="primary" size="large" block @click="onSubmit">提交</a-button>
		</div>
	</div>
</template>

<script>
	import { postUpdateWarnTime } from '@/service/modules/system.js';
	import topHeader from '@/views/jobs/components/topHeader.vue';
	export default {
		components: { topHeader },
		data() {
			return {
				num: null,
				modelRef: {
					num: undefined
				}
			}
		},
		methods: {
			// onInpChange(value) {
			// 	this.num = value
			// },
			onSubmit() {
				// console.log("提交数据",this.modelRef.num)
				this.$confirm({
					title: '提示',
					content: "确定提交预警时间吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await postUpdateWarnTime({
								time: this.modelRef.num,
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('提交成功');
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-timeBox, .ui-btnBox {
		/* display: flex;
		justify-content: center;
		align-items: center; */
		width: 100%;
		margin: 40px auto 20px;
	}
</style>