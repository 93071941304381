<template>
	<div class="ui-header">
		<div class="ui-header__text">
			<img class="ui-header__icon" src="@/assets/image/jobs/rectangle.png"/>
			{{ text }}
		</div>
		<div class="ui-head__more" v-if="hasMore" @click="handleClick">
			更多<Icon icon="RightOutlined" style="font-size: 12px;"></Icon>
		</div>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	export default {
		components: { Icon },
		props: {
			text: {
				type: String,
				default: ''
			},
			hasMore: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			handleClick() {
				this.$emit('moreClick');
			}
		}
	}
</script>

<style scoped>
	.ui-header {
		display: flex;
		/* padding-right: 30px; */
		justify-content: space-between;
	}
	.ui-header__text {
		font-size: 18px;
		font-weight: 500;
		color: #333333;
		line-height: 21px;
	}
	.ui-header__icon {
		width: 10px;
		height: 14px;
	}
	.ui-head__more {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: #4F7EF5;
		line-height: 16px;
		cursor: pointer;
	}
</style>